<template>
  <main id="content" role="main" class="overflow-hidden">
    <!-- header -->
    <!-- <header id="header" class="header">
      <div class="header-section" style="background-color: #f5f9fc;">
        <div id="logoAndNav" class="container">
          <nav class="js-mega-menu navbar navbar-expand-lg pt-lg-4">
            <router-link :to="{ path: '/' }" class="navbar-brand" aria-label="apidays.hk">
              <img src="@/assets/img/apidays-2023/images/apidays-2023-logo.png" alt="Logo">
            </router-link>
          </nav>
        </div>
      </div>
    </header> -->

    <div class="position-relative z-index-2 overflow-hidden" style="background-color: #f5f9fc;">

      <!-- Map -->
      <div class="container space-bottom-2 space-top-3">
        <div class="w-lg-60 text-center mx-lg-auto mb-5">
          <h1 class="mb-3">How to Get There</h1>
        </div>

        <div id="hkstplocation" class="w-lg-80 mx-auto">
          <div class="card shadow text-center">
            <div class="card-body">
              <h3>HKSTP Conference Halls 4-7</h3>
              <p>Conference Halls 4-7, 2/F, Lakeside 2,<br>Phase 2, Hong Kong Science Park</p>
              <p><a href="https://www.google.com/maps/place/Lakeside+2,+10+Science+Park+W+Ave,+Science+Park/@22.4267244,114.210722,17z/data=!3m1!4b1!4m6!3m5!1s0x34040897409caedf:0xe759bb6a4a3dc2cc!8m2!3d22.4267244!4d114.210722!16s%2Fg%2F12hs1qdk_?entry=ttu" target="_blank">View in Map <i class="fas fa-external-link-alt ml-2" /></a></p>
              <a
                class="js-fancybox media-viewer"
                href="javascript:;"
                data-hs-fancybox-options="{
              &quot;selector&quot;: &quot;#hkstplocation .js-fancybox&quot;,
              &quot;speed&quot;: 700
            }"
                :data-src="require('@/assets/img/apidays-2023/images/hkstp-location.jpg')"
                data-caption="HKSTP Conference Halls 4-7"
              >
                <img class="img-fluid" src="@/assets/img/apidays-2023/images/hkstp-location.jpg" alt="HKSTP Conference Halls 4-7">
              </a>
            </div>
          </div>

        </div>
      </div>

      <!-- Transportation -->
      <div id="ticket" style="background-color: #ffffff">
        <div class="container space-2">
          <div class="w-lg-60 text-center mx-lg-auto mb-5">
            <h1 class="mb-2">Transportation</h1>
            <p class="lead">The venue is conveniently accessible through a diverse range of transportation options.</p>
          </div>
          <div class="row">
            <div class="col-lg-6 mb-5 mb-lg-0">
              <div class="card shadow">
                <div class="card-body">
                  <h3 class="mb-3"><i class="fas fa-bus-alt mr-2" /> By Bus</h3>
                  <table class="table">
                    <tbody>
                      <tr>
                        <th scope="row">272K</th>
                        <td>Between MTR University Station Bus Terminus and Science Park</td>
                      </tr>
                      <tr>
                        <th scope="row">272S</th>
                        <td>Between MTR Diamond Hill Station Bus Terminus and Science Park</td>
                      </tr>
                      <tr>
                        <th scope="row">272X</th>
                        <td>Between Tai Po Central and Mong Kok East Station</td>
                      </tr>
                      <tr>
                        <th scope="row">43P</th>
                        <td>Between MTR Tsuen Wan West Station Bus Terminus and Science Park</td>
                      </tr>
                      <tr>
                        <th scope="row">74D</th>
                        <td>Between Kwun Tong Ferry Bus Terminus and Tai Po Kau Lung Hang (via Science Park)</td>
                      </tr>
                      <tr>
                        <th scope="row">74P</th>
                        <td>Between Kwun Tong Ferry Bus Terminus and Tai Po Central (via Science Park)</td>
                      </tr>
                      <tr>
                        <th scope="row">82C</th>
                        <td>Between Shatin Kwong Yuen Bus Terminus and Science Park (Phase 3)</td>
                      </tr>
                      <tr>
                        <th scope="row">263A</th>
                        <td>Between Tuen Mun Station and Science Park (Phase 3)</td>
                      </tr>
                      <tr>
                        <th scope="row">272P</th>
                        <td>Between Tai Po Fu Heng Bus Terminus and Kwai Hing Station (via Science Park)</td>
                      </tr>
                      <tr>
                        <th scope="row">274P</th>
                        <td>Between Wu Kai Sha Station and Tai Po Industrial Estate (via Science Park)</td>
                      </tr>
                      <tr>
                        <th scope="row">271B</th>
                        <td>Between Tsim Sha Tsui Middle Road and Tai Po Fu Heng Bus Terminus (via Science Park)</td>
                      </tr>
                      <tr>
                        <th scope="row">96</th>
                        <td>Between Hong Sing Garden and Tai Po Industrial Estate</td>
                      </tr>
                    </tbody>
                  </table>
                  <span>Visit <a href="http://search.kmb.hk/KMBWebSite/index.aspx?lang=en" target="_blank">Kowloon Motor Bus (KMB) Official Site</a> for the timetable of different bus route.</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card shadow mb-5">
                <div class="card-body">
                  <h3 class="mb-3"><i class="fas fa-bus mr-2" /> By Minibus</h3>
                  <table class="table">
                    <tbody>
                      <tr>
                        <th scope="row">GREEN MINIBUS NO. 27</th>
                        <td>Between Sha Tin MTR Station (Pai Tau Street) and Science Park</td>
                      </tr>
                      <tr>
                        <th scope="row">GREEN MINIBUS NO. 29</th>
                        <td>Between Ma Liu Shui Ferry Pier and Wan Tau Tong (via Pak Shek Kok)</td>
                      </tr>
                      <tr>
                        <th scope="row">GREEN MINIBUS NO. 806A</th>
                        <td>Between Wong Nai Tau and Wan Tau Tong (via Pak Shek Kok)</td>
                      </tr>
                      <tr>
                        <th scope="row">GREEN MINIBUS NO. 806B</th>
                        <td>Between Shek Mun and Wan Tau Tong (via Pak Shek Kok)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card shadow">
                <div class="card-body">
                  <h3 class="pb-3 border-bottom"><i class="fas fa-car mr-2" /> By Car</h3>
                  <p>Drive to Science Park via the Tolo Highway. Your trip should take 30 minutes from Tsim Sha Tsui, and 30 minutes from Shenzhen.</p>
                  <div class="mb-4">
                    <h5 class="mb-0">Parking Facility</h5>
                    <p>Phase 2 Car Park (with a charging station for electric cars)<br>
                      G/F, Customer Services Counter, Lakeside 1 (8W) / Lakeside 2 (10W)</p>
                  </div>
                  <div class="mb-4">
                    <h5 class="mb-0">Parking Rates</h5>
                    <p>Find out the parking rates, car park rules and regulations <a href="https://www.hkstp.org/media/fn2mkevw/appendix-a8-carpark-management-policy_eft-19-jan-2023.pdf" target="_blank">here</a>.</p>
                  </div>
                  <div class="mb-4">
                    <h5 class="mb-0">Parking Promotion</h5>
                    <p>Enjoy free parking by spending a designated amount. For more information please refer to <a href="https://www.hkstp.org/media/gutekklx/free-parking-at-hong-kong-science-park-effective-from-1-june-2023-_r4.pdf" target="_blank">here</a>.</p>
                  </div>
                  <div>
                    <h6 class="mb-0 small">Notes:</h6>
                    <span class="small">Service Charges or Fees are subject to change. HKSTP has the final discretion on the interpretation of and any matter arising from or in connection with this rates information.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-5">
            <p>Further information of how to get to HKSTP is available at <a href="https://www.hkstp.org/find-us/">here</a>.</p>
          </div>
        </div>
      </div>

    </div>

  </main>
</template>

<script>
const $ = require('jquery')
import '../../../../assets/js/hs.core.js'
import '../../../../assets/js/hs.fancybox.js'
import '../../../../assets/vendor/fancybox/dist/jquery.fancybox.js'
import 'bootstrap'

export default {
  name: 'Transportation',
  mounted() {
    // this.loadScriptRecapSubscribeForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "4daac438-a701-4a30-89de-fefdd7a7306f"
    //     });
    //   `
    //   this.$refs.recapSubscribeForm.appendChild(secondScript)
    // })
    // window.addEventListener('message', event => {
    //   if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
    //     this.isSubmitted = true
    //   }
    // })
    $('.js-fancybox').each(function() {
      $.HSCore.components.HSFancyBox.init($(this))
    })
  },
  metaInfo() {
    return {
      title: 'Apidays Hong Kong x HKUST EC | Coupon | Apidays Connect Hong Kong 2023',
      titleTemplate: 'Apidays Hong Kong x HKUST EC | Coupon | Apidays Connect Hong Kong 2023',
      link: [
        { rel: 'canonical', href: 'https://www.apidays.hk/coupon/hkust-ec' }
      ],
      meta: [
        { name: 'description', content: 'Apidays Hong Kong x HKUST EC | Coupon | Apidays Connect Hong Kong 2023' },
        { property: 'og:site_name', content: 'Apidays Hong Kong x HKUST EC | Coupon | Apidays Connect Hong Kong 2023' },
        { property: 'og:title', content: 'Apidays Connect Hong Kong 2023' },
        { property: 'og:description', content: '30-31 Aug, 2023 - The API Landscape of Hong Kong | apidays Connect Hong Kong 2023 is going to bring together leading API experts both local and international to share their seasonal insights and lessons learnt in driving the team and business under the “new normal”.' },
        { property: 'og:image', content: 'https://www.apidays.hk/assets/img/thumbnails/apidays-hongkong-2023.jpg' },
        { property: 'og:url', content: 'https://www.apidays.hk/coupon/hkust-ec' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  @import url("../../../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");
  </style>

